import React, { createContext } from 'react'
import useSignalrPublic from 'src/signalr/useSignalrPublic'

export const SignalRPublicContext = createContext()

export const SignalRPublicProvider = ({ children }) => {
  const { gameInfo, gameOdds, declare, payOut, connectionState } = useSignalrPublic()

  return (
    <SignalRPublicContext.Provider value={{ gameInfo, gameOdds, declare, payOut, connectionState }}>
      {children}
    </SignalRPublicContext.Provider>
  )
}
