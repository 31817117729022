import React, { createContext } from 'react'
import useSignalrPrivate from 'src/signalr/useSignalrPrivate'

export const SignalRPrivateContext = createContext()

export const SignalRPrivateProvider = ({ children }) => {
  const { signalWallet, signalBetUpdate, connectionStatePrivate } = useSignalrPrivate()
  return (
    <SignalRPrivateContext.Provider
      value={{ signalWallet, signalBetUpdate, connectionStatePrivate }}
    >
      {children}
    </SignalRPrivateContext.Provider>
  )
}
