import { useState, useEffect } from 'react'
import { LogLevel } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import getGameType from 'src/api/gameApi/getGameType'

const useSignalrPublic = () => {
  const [gameInfo, setGameInfo] = useState()
  const [gameOdds, setGameOdds] = useState({})
  const [declare, setDeclare] = useState()
  const [payOut, setPayOut] = useState({})
  const [gamRoomInfo, setGameRoomInfo] = useState({})
  const [gameType, setGameType] = useState('')
  const [connectionState, setConnectionState] = useState()

  const handleGetGameType = async () => {
    try {
      const response = await getGameType()
      setGameType(response.data[0].id)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleGetGameType()
  }, [])

  const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNAL_R_PUBLIC_URL)
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  const handleSignalRResponse = (message) => {
    const [messageType, data] = message.split('|')
    if (messageType === 'GameRoomCreated' || messageType === 'GameRoomEnd') {
      const publicData = JSON.parse(data)

      setGameRoomInfo(publicData)
    }
    if (
      messageType === 'GameCreated' ||
      messageType === 'GameOpenBet' ||
      messageType === 'GameClosedBet' ||
      messageType === 'GameStart' ||
      messageType === 'GameEnd' ||
      messageType === 'GameCancelled'
    ) {
      const publicData = JSON.parse(data)
      setGameInfo(publicData)
    }
    if (messageType === 'GameDeclareWinner') {
      const publicData = JSON.parse(data)
      setDeclare(publicData)
    }
    if (messageType === 'GamePayout') {
      const publicData = JSON.parse(data)
      setPayOut(publicData)
    }
    if (messageType === 'ODDS') {
      const publicData = JSON.parse(data)
      setGameOdds(publicData)
    }
  }

  const connectToSignalR = () => {
    newConnection
      .start()
      .then(() => {
        setConnectionState(newConnection.state)
        console.log('Connected public!')
        newConnection.send('RegisterToFeed', gameType)
      })
      .catch((e) => console.log('Connection failed: ', e))
  }

  newConnection.on('ReceivePublicUpdate', (message) => {
    handleSignalRResponse(message)
  })

  newConnection.onclose(() => {
    console.log(newConnection.state)
    setConnectionState(newConnection.state)
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (connectionState === 'Disconnected') {
        console.log('Reconnecting to Signal R')
        connectToSignalR()
      } else {
        clearInterval(interval)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [connectionState])

  useEffect(() => {
    if (newConnection.state === 'Disconnected') {
      connectToSignalR()
    }
  }, [gameType])
  return { gamRoomInfo, gameInfo, gameOdds, declare, payOut, connectionState }
}

export default useSignalrPublic
