import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const Game = React.lazy(() => import('src/views/game/Game'))
const BetReports = React.lazy(() => import('src/views/bhistory/BetReports'))
const Profile = React.lazy(() => import('src/views/profile/Profile'))
const EditProfile = React.lazy(() => import('src/views/profile/EditProfile'))
const ChangePassword = React.lazy(() => import('src/views/profile/ChangePassword'))
const Request = React.lazy(() => import('src/views/request/Request'))
const MyRequest = React.lazy(() => import('src/views/request/MyRequest'))
const RequestWallet = React.lazy(() => import('src/views/wallet/RequestWallet'))
const RequestDetails = React.lazy(() => import('src/views/wallet/RequestDetails'))
const Login = React.lazy(() => import('src/views/login/Login'))
const AuthLayout = React.lazy(() => import('src/components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('src/components/layout/MainLayout'))

export const PlayerRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/game', name: 'Arena', element: <Game /> },
      { path: '/reports', name: 'Reports', element: <BetReports /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'MyRequest', element: <MyRequest /> },
      { path: '/request-wallet', name: 'Request Wallet', element: <RequestWallet /> },
      { path: '/request-details', name: 'Request Details', element: <RequestDetails /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/game" />,
  },
]

export const defaultRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]
