import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import './scss/style.scss'
import ToastContainerComponent from './components/toast/ToastContainerComponent'
import { PlayerRoutes, defaultRoutes } from './routes/routes'
import { useAuth } from 'src/hooks/auth/useAuth'

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const selectRole = (userId) => {
  if (userId !== 9) {
    return defaultRoutes
  } else {
    return PlayerRoutes
  }
}

const App = () => {
  const { token, setToken } = useAuth()
  const routesResultData = selectRole(token?.userType)
  const routesResult = useRoutes(routesResultData)
  const url = `${process.env.REACT_APP_PLAYERAUTH_URL}/api/Auth`

  return (
    <>
      <Suspense fallback={<Loading />}>{routesResult}</Suspense>
      <ToastContainerComponent />
    </>
  )
}

export default App
