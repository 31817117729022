import { useState, useEffect } from 'react'
import { LogLevel } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import { useAuth } from 'src/hooks/auth/useAuth'

const useSignalrPrivate = () => {
  const [signalWallet, setSignalWallet] = useState({})
  const [signalBetUpdate, setSignalBetUpdate] = useState({})
  const [connectionStatePrivate, setConnectionStatePrivate] = useState(0)

  const { token } = useAuth()

  const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNAL_R_PRIVATE_URL)
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  const handleSignalRResponse = (message) => {
    // handle the response here
    const [messageType, data] = message.split('|')

    if (messageType === 'WALLET_UPDATE') {
      const walletData = JSON.parse(data)
      setSignalWallet(walletData)
    }
    if (messageType === 'BET_UPDATE') {
      const betData = JSON.parse(data)
      setSignalBetUpdate(betData)
    }
  }

  const connectToSignalR = () => {
    newConnection
      .start()
      .then(() => {
        setConnectionStatePrivate(newConnection.state)
        console.log('Connected private!')
        newConnection.send('RegisterToFeed', token?.userId)
      })
      .catch((e) => console.log('Connection failed: ', e))
  }

  newConnection.on('ReceivePrivateUpdate', (message) => {
    handleSignalRResponse(message)
  })

  newConnection.onclose(() => {
    console.log(newConnection.state)
    setConnectionStatePrivate(newConnection.state)
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (connectionStatePrivate === 'Disconnected') {
        console.log('Reconnecting to Signal R')
        connectToSignalR()
      } else {
        clearInterval(interval)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [connectionStatePrivate])

  useEffect(() => {
    if (newConnection.state === 'Disconnected') {
      connectToSignalR()
    }
  }, [token?.userId])

  return { signalWallet, signalBetUpdate, connectionStatePrivate }
}

export default useSignalrPrivate
